
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    border-radius: 5px;
}

.react-tagsinput--focused {
    border-color: #1890ff;
    /* border: solid 1px 1890ff; */
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    /* font-weight: 400; */
    margin-top: 1px;
    margin-bottom: 6px;
    outline: none;;
    padding: 5px;
    width: 165px;
}

.react-tagsinput-tag {
    background-color: #e6e6e6;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    color: #373737;
    font-family: Roboto, sans-serif !important;
    font-size: 13px;
    /* display: inline-block; */
    /* font-family: sans-serif; */
    /* font-size: 14px; */
    /* font-weight: 400; */
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 3px;
}

.react-tagsinput-remove {
    font-size: 15px;
    color: #1e1e1e;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

.buttons-container {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
}

.close-and-arrow {
    color: hsl(0, 0%, 80%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;
}

.line-bettwen {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.svg-button {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}


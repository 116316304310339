* {
    box-sizing: border-box;
}

html, body, #root {
    height: 100%
}

body {
    font-family: 'Roboto', sans-serif !important;
    margin: 0;
}

body.appointment-overlay main {
    overflow: hidden;
}

ul {
    margin: 0;
}

.auto-overflow {
    overflow: auto!important;
}

.highlight {
  background-color: yellow !important;
}


@font-face {
    font-family: "NestleText-Bold";   /*Can be any text*/
    src: local("NestleText-Bold"),
      url("./fonts/NestleText-Bold.otf") format("otf");
  }

  @font-face {
    font-family: "NestleText-BoldItalic";   /*Can be any text*/
    src: local("NestleText-BoldItalic"),
      url("./fonts/NestleText-BoldItalic.otf") format("otf");
  }
  
  @font-face {
    font-family: "NestleText-Book";   /*Can be any text*/
    src: local("NestleText-Book"),
      url("./fonts/NestleText-Book.otf") format("otf");
  }
  
  @font-face {
    font-family: "NestleText-Italic";   /*Can be any text*/
    src: local("NestleText-Italic"),
      url("./fonts/NestleText-Italic.otf") format("otf");
  }
  
  @font-face {
    font-family: "NestleText-Light";   /*Can be any text*/
    src: local("NestleText-Light"),
      url("./fonts/NestleText-Light.otf") format("otf");
  }  

  @font-face {
    font-family: "NestleText-LightItalic";   /*Can be any text*/
    src: local("NestleText-LightItalic"),
      url("./fonts/NestleText-LightItalic.otf") format("otf");
  }

  .pslVendorInfo .ant-modal-header {
    background: #f19001;
  }

  .pslVendorInfo .subHeadingFont {
    font-weight: 500;
  }

  .pslVendorInfo .ant-modal-close {
    color: white;
  }

  .pslVendorInfo .ant-modal-title {
    color: white;
  }

.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    border-radius: 5px;
}

.react-tagsinput--focused {
    border-color: #1890ff;
    /* border: solid 1px 1890ff; */
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    /* font-weight: 400; */
    margin-top: 1px;
    margin-bottom: 6px;
    outline: none;;
    padding: 5px;
    width: 165px;
}

.react-tagsinput-tag {
    background-color: #e6e6e6;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    color: #373737;
    font-family: Roboto, sans-serif !important;
    font-size: 13px;
    /* display: inline-block; */
    /* font-family: sans-serif; */
    /* font-size: 14px; */
    /* font-weight: 400; */
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 3px;
}

.react-tagsinput-remove {
    font-size: 15px;
    color: #1e1e1e;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

.buttons-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.close-and-arrow {
    color: hsl(0, 0%, 80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
}

.line-bettwen {
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.svg-button {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}


* {
    box-sizing: border-box;
}

html, body, #root {
    height: 100%
}

body {
    font-family: 'Roboto', sans-serif !important;
    margin: 0;
}

body.appointment-overlay main {
    overflow: hidden;
}

ul {
    margin: 0;
}

.auto-overflow {
    overflow: auto!important;
}

.highlight {
  background-color: yellow !important;
}


@font-face {
    font-family: "NestleText-Bold";   /*Can be any text*/
    src: local("NestleText-Bold"),
      url(/static/media/NestleText-Bold.93f2ddce.otf) format("otf");
  }

  @font-face {
    font-family: "NestleText-BoldItalic";   /*Can be any text*/
    src: local("NestleText-BoldItalic"),
      url(/static/media/NestleText-BoldItalic.2a8309e0.otf) format("otf");
  }
  
  @font-face {
    font-family: "NestleText-Book";   /*Can be any text*/
    src: local("NestleText-Book"),
      url(/static/media/NestleText-Book.c0b33dc4.otf) format("otf");
  }
  
  @font-face {
    font-family: "NestleText-Italic";   /*Can be any text*/
    src: local("NestleText-Italic"),
      url(/static/media/NestleText-Italic.f7db80ba.otf) format("otf");
  }
  
  @font-face {
    font-family: "NestleText-Light";   /*Can be any text*/
    src: local("NestleText-Light"),
      url(/static/media/NestleText-Light.e7009c92.otf) format("otf");
  }  

  @font-face {
    font-family: "NestleText-LightItalic";   /*Can be any text*/
    src: local("NestleText-LightItalic"),
      url(/static/media/NestleText-LightItalic.2316609a.otf) format("otf");
  }

  .pslVendorInfo .ant-modal-header {
    background: #f19001;
  }

  .pslVendorInfo .subHeadingFont {
    font-weight: 500;
  }

  .pslVendorInfo .ant-modal-close {
    color: white;
  }

  .pslVendorInfo .ant-modal-title {
    color: white;
  }
